import React from "react";
import './App.css';

function App() {
  return (
    <div>
      <section class="landing">
        <h1>Hi, I'm Ashish Mohite.</h1>
      </section>
      <section className="links">
        <a href="https://ashish16052.notion.site/Blog-1250ec7c0527806cace6f310e13f9598" target='_blank' rel="noopener noreferrer">
          Blogs
        </a>
        <a href="https://github.com/ashish16052?tab=repositories" target='_blank' rel="noopener noreferrer">
          Projects
        </a>
        <a href="https://www.youtube.com/@asheeesh_yt" target='_blank' rel="noopener noreferrer">
          Guitar
        </a>
      </section>
      <section className="skills">
        <p>
          Python, JavaScript, C++, React, Angular, Nextjs, Redux, Html, Css, Scss, Onyx, Bootstrap, Tailwind, TypeScript, Node,
          Express, Mongo, GeoDude, MySQL, Django, Flask, PySpark, PyArrow, Abra, Redis, GraphQL, AWS, Docker, Jenkins, Nginx,
          SQLAlchemy, Firebase, Heroku, Wordpress, Jupyter, Vulpix, GitHub, Digital Ocean, Bitbucket, Arbok, Notion
        </p>
        <h3 className="pokemon">(Can you spot the hidden Pokemon?)</h3>
      </section>
      <section className="socials">
        <a href="https://github.com/ashish16052/" target="_blank" rel="noopener noreferrer">
          github.com/ashish16052
        </a>
        <a href="https://www.linkedin.com/in/ashish-s-mohite" target="_blank" rel="noopener noreferrer">
          linkedin.com/in/ashish-s-mohite
        </a>
        <a href="mailto:ashishmohite16052@gmail.com" target="_blank" rel="noopener noreferrer">
          ashishmohite16052@gmail.com
        </a>
      </section>
    </div>
  );
}

export default App;
